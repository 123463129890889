import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        position: 'absolute',
        top: '91%',
        alignSelf: 'center'
    },
    leftContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '50%',
        margin: '5 5 5 5'
    },
    rightContainer: {
        width: '50%',
        alignContent: 'center',
        alignItems: 'flex-end',
        margin: '50 5 5 5'
    },
    stamp: {
        width: '50px',
        height: 'auto'
    },
});

const PAID_STAMP_URL = 'https://res.cloudinary.com/dfvcuxviq/image/upload/v1708351800/invoice/paid-stamp-png_p5gteh.png'

const InvoiceThankYouMsg = () => (
    <View style={styles.mainContainer}>
        <View style={styles.leftContainer}>
            <Text style={{
                fontSize: '8px',
                margin: '5 5 0 10'
            }}>E. & O.E</Text>
            <Image style={styles.stamp} src={PAID_STAMP_URL} />
        </View>
        <View style={styles.rightContainer}>
            <Text style={{
                fontSize: '8px'
            }}>
                For, Fable Street Lifestyle Solutions Private Limited
            </Text>
            <Text style={{
                fontSize: '8px'
            }}>
                This is a computer generated invoice and does not require a signature
            </Text>
        </View>
    </View>
);

export default InvoiceThankYouMsg