import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 2,
        justifyContent: 'space-between'
    },
    reportTitle: {
        color: '#000000',
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    originalTitle: {
        color: '#000000',
        fontSize: '12px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    leftContainer: {
        flexDirection: 'column'
    },
    rightContainer: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: 5
    },
    gstinContainer: {
        fontSize: '8px',
        fontWeight: 'extrabold'
    },
    cinContainer: {
        fontSize: '8px',
        fontWeight: 800
    },
    panContainer: {
        fontSize: '8px',
        fontWeight: 800
    }
});


const InvoiceHeader = ({ title, gstin, cin, panNumber }) => (
    <View style={styles.titleContainer}>
        <View style={styles.leftContainer}>
            <Text style={styles.reportTitle}>{title}</Text>
            <Text style={styles.gstinContainer}>GSTIN: {gstin}</Text>
            <Text style={styles.cinContainer}>CIN: {cin}</Text>
        </View>
        <View style={styles.rightContainer}>
            <Text style={styles.originalTitle}>Original</Text>
            <Text style={styles.panContainer}>PAN: {panNumber}</Text>
        </View>
    </View>
);

export default InvoiceHeader;