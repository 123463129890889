import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isExpired } from "react-jwt";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    AppBar,
    Toolbar,
    TextField,
    Button,
    Alert,
    Snackbar
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const fileConstants = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    ACCESS_KEY_NAME: process.env.REACT_APP_ACCESS_KEY_NAME,
    REFRESH_KEY_NAME: process.env.REACT_APP_REFRESH_KEY_NAME
}

export default function Dashboard() {
    const parameters = useParams();
    const locationId = parameters?.locationId;
    const [fetching, setFetching] = useState(false);
    const [ordersData, setOrdersData] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [error, setError] = useState(null);
    const [numRetries, setNumRetries] = useState(0);

    useEffect(() => {
        const accessKey = localStorage.getItem(fileConstants.ACCESS_KEY_NAME);
        const refreshKey = localStorage.getItem(fileConstants.REFRESH_KEY_NAME);
        if (accessKey && refreshKey) {
            // check validity and move forward
            const isAccessExpired = isExpired(accessKey);
            const isRefreshExpired = isExpired(refreshKey);
            if (isRefreshExpired) {
                localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
                localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
                window.location = `/store/${locationId}/login`;
            } else {
                if (isAccessExpired) {
                    refreshAccessToken(refreshKey);
                } else {
                    fetchOrders(accessKey);
                }
            }
        }
        if (accessKey && !refreshKey) {
            // remove the accessKey and ask to re login
            localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
            localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
            window.location = `/store/${locationId}/login`;
        }
        if (!accessKey && refreshKey) {
            // fetch new access key and move accordingly
            const isRefreshExpired = isExpired(refreshKey);
            if (isRefreshExpired) {
                localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
                localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
                window.location = `/store/${locationId}/login`;
            } else {
                refreshAccessToken(refreshKey);
            }
        }
        if (!accessKey && !refreshKey) {
            // ask to login
            window.location = `/store/${locationId}/login`;
        }
    }, []);

    useEffect(() => {
        if (numRetries <= 10 && searchText && searchText.length >= 4) {
            const timeOut = (numRetries + 1) * (60 * 1000);
            setTimeout(() => {
                searchOrder({
                    target: {
                        value: searchText
                    }
                }, true);
            }, timeOut);
        }
    }, [numRetries]);

    const refreshAccessToken = async (refreshToken) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "refreshToken": refreshToken
            })
        };
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/refresh`, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                if (data.success) {
                    localStorage.setItem(fileConstants.ACCESS_KEY_NAME, data?.accessToken);
                    localStorage.setItem(fileConstants.REFRESH_KEY_NAME, data?.refreshToken);
                    fetchOrders(data?.accessToken);
                } else {
                    setError(data.message);
                }
            })
            .catch(async (error) => {
                console.log(error);
                window.location = `/store/${locationId}/login`;
            });
    }

    const fetchOrders = async (accessToken, orderNumId, retry = false) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }
        };
        let apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/orders-invoice`;

        if (orderNumId) {
            apiEndpoint = apiEndpoint + `?orderNumId=${orderNumId}`;
            setSearchText(orderNumId);
        }

        await fetch(apiEndpoint, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                if (data.success) {
                    setOrdersData(data.orders);
                    if (data.orders.length === 0 && retry) {
                        setNumRetries(numRetries + 1);
                    }
                } else {
                    setError(data.message);
                }
            })
            .catch(async (error) => {
                console.log(error);
                setError(`Something went Wrong`);
            });
    }

    const triggerOrderRefresh = async () => {
        const accessToken = localStorage.getItem(fileConstants.ACCESS_KEY_NAME);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }
        };
        let apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/fetch-orders`;

        if (searchText) {
            apiEndpoint = apiEndpoint + `?orderNumId=${searchText}&locationId=${locationId}`;
        }

        await fetch(apiEndpoint, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                setFetching(true);
            })
            .catch(async (error) => {
                console.log(error);
                setError(`Something went Wrong`);
            });
    }


    const searchOrder = async (e, retry = false) => {
        const searchTerm = e.target.value;
        if (searchTerm) {
            if (searchTerm.length >= 4) {
                fetchOrders(localStorage.getItem(fileConstants.ACCESS_KEY_NAME), searchTerm, retry);
            } else {
                fetchOrders(localStorage.getItem(fileConstants.ACCESS_KEY_NAME));
            }
        } else {
            fetchOrders(localStorage.getItem(fileConstants.ACCESS_KEY_NAME));
        }
    }

    return (
        <>
            <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
                <AppBar position="fixed" sx={{ backgroundColor: "whitesmoke" }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "black" }}>
                            Fablestreet
                        </Typography>
                        {/* Search box */}
                        <Box
                            width="60%"
                            height="50%"
                        >
                            <TextField
                                id="search-box"
                                label="Search Order Id or Order Number"
                                variant="outlined"
                                sx={{ width: "100%", height: "auto" }}
                                onChange={(e) => searchOrder(e)}
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <br />
            <br />
            <br />
            <Box
                width="96vw"
                alignSelf="center"
                margin="auto"
            >
                {
                    ordersData.length ?
                        (<InvoiceTable orders={ordersData} />) :
                        (
                            <>
                                {
                                    searchText ?
                                        (
                                            <>
                                                {
                                                    (searchText.length >= 4) ?
                                                        (
                                                            <>
                                                                <p>{searchText} not found, Click on fetch and recheck after a while </p>
                                                                <br />
                                                                {
                                                                    fetching ?
                                                                        (<><p>Fetching, please wait</p></>) :
                                                                        (<Button variant="outlined" onClick={triggerOrderRefresh}>Fetch Order {searchText}</Button>)
                                                                }

                                                            </>
                                                        ) :
                                                        (<p>Search term too short ...</p>)
                                                }
                                            </>
                                        ) :
                                        (<p>Loading...</p>)
                                }
                            </>
                        )
                }
            </Box>
        </>
    );
}

function InvoiceTableRow(props) {
    const { order } = props;
    // console.log(JSON.stringify(order));
    const [open, setOpen] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [invalidMobileLength, setInvalidMobileLength] = useState(false);
    const [mobileHelperText, setMobileHelperText] = useState("");
    const [whatsAppMobileNumber, setWhatsAppMobileNumber] = useState(null);

    const invoiceDetails = order["invoice_details"];
    const items = invoiceDetails["items"];
    const shipToParty = invoiceDetails["shipToPartyDetails"];

    let customerName = "Valued Customer";
    let phoneNumber = null;
    if (shipToParty["name"] && shipToParty["name"].toLowerCase !== "na" && shipToParty["name"].length > 3) {
        customerName = shipToParty["name"];
    }

    if (shipToParty["phoneNumber"] && shipToParty["phoneNumber"].toLowerCase !== "-" && shipToParty["phoneNumber"].length > 3) {
        phoneNumber = shipToParty["phoneNumber"];
        if (phoneNumber.includes("+")) {
            phoneNumber = phoneNumber.replace("+91", "");
        }
    }

    const invoiceLink = `https://invoice.fslife.co/invoice/${order["order_id"]}/${order["uuid"]}`;

    const sendInvoice = async () => {
        let whatsAppTriggerNumber = null;
        if (whatsAppMobileNumber) {
            whatsAppTriggerNumber = whatsAppMobileNumber;
        } else {
            whatsAppTriggerNumber = phoneNumber;
        }

        if (whatsAppTriggerNumber.length < 10) {
            setSnackbarSeverity("warning");
            setSnackbarMessage(`Mobile Number ${whatsAppTriggerNumber} seems invalid`);
        } else {
            callWhatsAppApi(whatsAppTriggerNumber);
        }
        setSnackBarOpen(true);
    }

    const callWhatsAppApi = async (mobileNumber) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(fileConstants.ACCESS_KEY_NAME)
            },
            body: JSON.stringify({
                "mob": `91${mobileNumber}`,
                "userName": customerName,
                "invoiceLink": invoiceLink
            })
        };

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/trigger-message`, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                console.log(data, '<<<<<<');
                if (data.success) {
                    setSnackbarSeverity("success");
                    setSnackbarMessage(`Message triggered to ${mobileNumber} successfully`);
                } else {
                    setSnackbarSeverity("error");
                    setSnackbarMessage(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setSnackbarSeverity("error");
                setSnackbarMessage("Something went wrong");
            });
    }

    const handleSnackbarClose = () => {
        setSnackBarOpen(false);
    }

    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        if (value) {
            if (value.length < 10) {
                setInvalidMobileLength(true);
                setWhatsAppMobileNumber(value);
                setMobileHelperText("Mobile should be of atleast length 10");
            } else {
                setInvalidMobileLength(false);
                setMobileHelperText("");
                setWhatsAppMobileNumber(value);
            }
        }
    }

    const maskNumber = (phoneNumber) => {
        if (phoneNumber) {
            if (phoneNumber.length > 8) {
                return `${phoneNumber.substring(0, 2)}XXXXX${phoneNumber.substring(7, phoneNumber.length)}`;
            } else {
                return "";
            }
        } else {
            return phoneNumber;
        }
    }

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {order["invoice_id"]}
                </TableCell>
                <TableCell align="right">{invoiceDetails["invoiceDetails"]["orderNumber"]}</TableCell>
                <TableCell align="right">{order["order_id"]}</TableCell>
                <TableCell align="right">{invoiceDetails["invoiceDetails"]["invoiceDate"]}</TableCell>
                <TableCell align="right"><a href={invoiceLink} target="_blank"> Invoice </a></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Items
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item) => (
                                        <TableRow key={item["itemSku"]}>
                                            <TableCell component="th" scope="row">
                                                {item["itemSku"]}
                                            </TableCell>
                                            <TableCell>{item["rate"]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {/* button to trigger a message send */}
                            <Box
                                display="flex"
                                flexDirection="row"
                                marginLeft={2}
                                marginTop={2}
                                marginBottom={2}
                            >
                                <TextField
                                    error={invalidMobileLength}
                                    id="outlined-helperText"
                                    label="10 digit mobile number"
                                    defaultValue={maskNumber(phoneNumber)}
                                    sx={{
                                        marginRight: "10px",
                                        width: "18rem"
                                    }}
                                    helperText={mobileHelperText}
                                    onChange={handleMobileNumberChange}
                                />
                                <Button
                                    variant="outlined"
                                    sx={{
                                        margin: "5px"
                                    }}
                                    onClick={sendInvoice}
                                >
                                    Send Invoice
                                </Button>
                            </Box>
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                open={snackBarOpen}
                                onClose={handleSnackbarClose}
                                key={"top_right_snack"}
                            >
                                <Alert
                                    onClose={handleSnackbarClose}
                                    severity={snackbarSeverity}
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function InvoiceTable({ orders }) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Id</TableCell>
                        <TableCell align="right">Order Number</TableCell>
                        <TableCell align="right">Order Id</TableCell>
                        <TableCell align="right">Invoice Date</TableCell>
                        <TableCell align="right">Link</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order) => (
                        <InvoiceTableRow key={order["invoice_id"]} order={order} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}