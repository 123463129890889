import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isExpired } from "react-jwt";
import {
    Box,
    TextField,
    Button,
    Typography
} from "@mui/material";

// const BACKGROUND_URL = "https://res.cloudinary.com/dfvcuxviq/image/upload/v1719206428/ai_generated/herringbone-black-white-linen-fabric-with-arrow-lines-style-minimalist-illustrator_921860-175139_yvvo4r.png";
// style={{
//     width: "100vw",
//     height: "100vh",
//     backgroundImage: `url(${BACKGROUND_URL})`,
//     backgroundPosition: 'center',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     opacity: 0.12,
//     position: "fixed"
// }}

const fileConstants = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    ACCESS_KEY_NAME: process.env.REACT_APP_ACCESS_KEY_NAME,
    REFRESH_KEY_NAME: process.env.REACT_APP_REFRESH_KEY_NAME
}

const validateEmail = (email) => {
    const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    const success = emailRegex.test(email);
    if (success) {
        if (email.indexOf("fablestreet.com") > 0) {
            return {
                success: success,
                message: "valid email id"
            }
        } else {
            return {
                success: false,
                message: "Email id not allowed"
            }
        }
    } else {
        return {
            success,
            message: "Invalid email format"
        }
    }
}

export default function Login() {
    const parameters = useParams();
    const locationId = parameters?.locationId;

    // localStorage.getItem()
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        const accessKey = localStorage.getItem(fileConstants.ACCESS_KEY_NAME);
        const refreshKey = localStorage.getItem(fileConstants.REFRESH_KEY_NAME);
        if (accessKey && refreshKey) {
            // check validity and move forward
            const isAccessExpired = isExpired(accessKey);
            const isRefreshExpired = isExpired(refreshKey);
            if (isRefreshExpired) {
                localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
                localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
                setLoading(false);
            } else {
                if (isAccessExpired) {
                    refreshAccessToken(refreshKey);
                } else {
                    window.location = `/store/${locationId}/dashboard`
                }
            }
        }
        if (accessKey && !refreshKey) {
            // remove the accessKey and ask to re login
            localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
            localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
            setLoading(false);
        }
        if (!accessKey && refreshKey) {
            // fetch new access key and move accordingly
            const isRefreshExpired = isExpired(refreshKey);
            if (isRefreshExpired) {
                localStorage.removeItem(fileConstants.ACCESS_KEY_NAME);
                localStorage.removeItem(fileConstants.REFRESH_KEY_NAME);
                setLoading(false);
            } else {
                refreshAccessToken(refreshKey);
            }
        }
        if (!accessKey && !refreshKey) {
            // ask to login
            setLoading(false);
        }
    }, []);

    const callLoginApi = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "locationId": locationId,
                "email": email,
                "password": password
            })
        };
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/login`, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                if (data.success) {
                    localStorage.setItem(fileConstants.ACCESS_KEY_NAME, data?.accessToken);
                    localStorage.setItem(fileConstants.REFRESH_KEY_NAME, data?.refreshToken);
                    window.location = `/store/${locationId}/dashboard`;
                } else {
                    setError(data.message);
                }
            })
            .catch(async (error) => {
                console.log(error)
            });
    }

    const refreshAccessToken = async (refreshToken) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "refreshToken": refreshToken
            })
        };
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/refresh`, requestOptions)
            .then(async (response) => response.json())
            .then(async (data) => {
                if (data.success) {
                    localStorage.setItem(fileConstants.ACCESS_KEY_NAME, data?.accessToken);
                    localStorage.setItem(fileConstants.REFRESH_KEY_NAME, data?.refreshToken);
                    window.location = `/store/${locationId}/dashboard`;
                } else {
                    setError(data.message);
                }
            })
            .catch(async (error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        if (email === "" && password === "") {
            setError(null);
        }
    }, [email, password]);

    const updateEmail = (e) => {
        const inputText = e?.target?.value;
        if (inputText) {
            const validationResult = validateEmail(inputText);
            if (validationResult.success) {
                setEmail(inputText);
                setError(null);
            } else {
                setError(validationResult.message);
            }
        } else {
            setEmail("");
        }
    }

    const updatePassword = (e) => {
        const inputText = e?.target?.value;
        if (inputText) {
            setPassword(inputText)
        } else {
            setPassword("");
        }
    }

    return (
        <>
            {
                loading ?
                    (
                        <p>Loading</p>
                    )
                    :
                    (
                        <div>
                            <Box
                                height={500}
                                width={400}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                margin="auto"
                                marginTop="10vh"
                                p={2}
                                sx={{ border: '2px solid grey' }}
                                borderRadius={2}
                                zIndex={500}
                            >
                                <Box>
                                    <b>Login</b>
                                </Box>
                                {/* interactable components */}
                                <Box
                                    width="100%"
                                    marginTop={8}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    {/* input email */}
                                    <Box
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        marginTop={1}
                                    >
                                        <TextField
                                            id="input-email"
                                            label="Enter assgined email"
                                            variant="outlined"
                                            sx={{ width: "100%" }}
                                            onChange={(e) => updateEmail(e)}
                                        />
                                    </Box>
                                    {/* input password */}
                                    <Box
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        marginTop={3}
                                    >
                                        <TextField
                                            id="input-password"
                                            label="Enter password"
                                            variant="outlined"
                                            sx={{ width: "100%" }}
                                            type="password"
                                            onChange={(e) => updatePassword(e)}
                                        />
                                    </Box>
                                    {/* enter button */}
                                    <Box
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        marginTop={3}
                                        height="40%"
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={
                                                {
                                                    border: '2px solid grey',
                                                    color: "grey",
                                                    width: "100%",
                                                    height: "50px"
                                                }
                                            }
                                            onClick={callLoginApi}
                                        >
                                            Enter
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    marginTop={6}
                                >
                                    {
                                        error ?
                                            (
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    <Box sx={{ fontStyle: 'italic', m: 1, color: "red" }}>{error}</Box>
                                                </Typography>
                                            ) : <></>
                                    }
                                </Box>
                            </Box>
                        </div>
                    )
            }
        </>
    );
}