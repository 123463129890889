import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const borderColor = '#000000'

const config = {
    primaryColor: '#00000060',
    primaryRGB: [0, 0, 0],
    accentColor: '#000000',
    blackColor: '#000000',
    grayColor: '#000000',
    lightGrayColor: '#000000',
    sectionTitleFontSize: 13,
    fieldTitleFontSize: 11,
    normalFontSize: 10,
    dateFormat: 'DD MM YYYY'
}

const styles = StyleSheet.create({
    table: {
        width: '100%'
    },
    mainContainer: {
        flexDirection: 'column',
        marginTop: 5,
        borderColor: "#00000066",
        justifyContent: 'space-between'
    },
    text: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '2 2 0 0',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: config.primaryColor,
        color: '#000'
    },
    headerCell: {
        fontWeight: 'heavy',
        white: '#000',
        width: '11.11%',
        fontSize: config.normalFontSize,
        margin: '5 15 5 5'
    },
    headerCellSno: {
        fontWeight: 'heavy',
        white: '#000',
        width: '8%',
        fontSize: config.normalFontSize,
        margin: '5 15 5 5'
    },
    headerCellItemSku: {
        fontWeight: 'heavy',
        white: '#000',
        width: '14.22%',
        fontSize: config.normalFontSize,
        margin: '5 15 5 5'
    },
    body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5'
    },
    cell: {
        width: '11.11%',
        margin: '5 5 5 5',
    },
    cellSNo: {
        width: '8%',
        margin: '5 5 5 5',
    },
    cellItemSku: {
        width: '14.22%',
        margin: '5 5 5 5',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 3px',
        borderColor: borderColor
    }
})
const Table = ({ children }) => <View style={styles.table}>{children}</View>
const TableHeader = ({ children }) => (
    <View style={styles.header}>{children}</View>
)
const TableHeaderCell = ({ children }) => (
    <Text style={styles.headerCell}>{children}</Text>
)
const TableHeaderCellItemSku = ({ children }) => (
    <Text style={styles.headerCellItemSku}>{children}</Text>
)

const TableHeaderCellSNo = ({ children }) => (
    <Text style={styles.headerCellSno}>{children}</Text>
)
const TableBody = ({ children }) => <View style={styles.body}>{children}</View>
const TableRow = ({ children }) => <View style={styles.row}>{children}</View>
const TableCell = ({ children }) => <Text style={styles.cell}>{children}</Text>
const TableCellSno = ({ children }) => <Text style={styles.cellSNo}>{children}</Text>
const TableCellItemSku = ({ children }) => <Text style={styles.cellItemSku}>{children}</Text>

const InvoiceItemsTable = ({ invoice }) => {

    return (
        <View style={styles.mainContainer}>
            <Table>
                <InvoiceTableHeader />
                <TableBody>
                    {
                        invoice.items.map(item =>
                            <InvoiceTableRow item={item} />
                        )
                    }
                    <InvoiceTableFooter totalQty={invoice.items_total.qty} amount={invoice.items_total.amount} />
                </TableBody>
            </Table>
        </View>
    )
}

const InvoiceTableFooter = ({ totalQty, amount }) => {
    return (
        <TableRow>
            <TableCell>
                <Text style={styles.text}></Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>Total</Text>
            </TableCell>
            <TableCell>
                {/* <Text style={styles.text}>{invoice.items_total.qty.toString()}</Text> */}
                <Text style={styles.text}>{totalQty.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>-</Text>
            </TableCell>
            <TableCell>
                {/* <Text style={styles.text}>{invoice.items_total.amount.toString()}</Text> */}
                <Text style={styles.text}>{amount.toString()}</Text>
            </TableCell>
        </TableRow>
    )
}

const InvoiceTableHeader = () => {
    return (
        <TableHeader>
            <TableHeaderCellSNo>
                <Text style={styles.text}>S.No.</Text>
            </TableHeaderCellSNo>
            <TableHeaderCellItemSku>
                <Text style={styles.text}>Item - Sku</Text>
            </TableHeaderCellItemSku>
            <TableHeaderCell>
                <Text style={styles.text}>Qty</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>Rate</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>Discount</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>
                    Taxable
                    Amount
                </Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>HSN</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>GST Rate</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>CGST</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>SGST</Text>
            </TableHeaderCell>
            <TableHeaderCell>
                <Text style={styles.text}>Total</Text>
            </TableHeaderCell>
        </TableHeader>
    )
}

const InvoiceTableRow = ({ item }) => {
    return (
        <TableRow>
            <TableCellSno>
                <Text style={styles.text}>{item.sno.toString()}</Text>
            </TableCellSno>
            <TableCellItemSku>
                <Text style={styles.text}>{item.itemSku}</Text>
            </TableCellItemSku>
            <TableCell>
                <Text style={styles.text}>{item.qty.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.rate.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.discount.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.taxableAmount.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.hsn}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.gst.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.cgst.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.sgst.toString()}</Text>
            </TableCell>
            <TableCell>
                <Text style={styles.text}>{item.total.toString()}</Text>
            </TableCell>
        </TableRow>
    )
}

InvoiceItemsTable.Table = Table
InvoiceItemsTable.TableBody = TableBody
InvoiceItemsTable.InvoiceTableHeader = InvoiceTableHeader
InvoiceItemsTable.InvoiceTableRow = InvoiceTableRow
InvoiceItemsTable.InvoiceTableFooter = InvoiceTableFooter

export {
    Table,
    TableBody,
    InvoiceTableHeader,
    InvoiceTableRow,
    InvoiceTableFooter,
    InvoiceItemsTable
}