import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'row',
        marginTop: 5,
        borderTop: '1px',
        borderBottom: '1px',
        borderColor: "#00000066",
        // backgroundColor: "#00000040",
        justifyContent: 'space-evenly'
    },
    titleContainer: {
        flexDirection: 'row',
        borderBottom: '1px',
        borderColor: "#00000066",
        backgroundColor: "#00000040",
        width: '100%',
        justifyContent: 'center'
    },
    titleText: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '2 2 2 2',
    },
    nameContainer: {
        width: '100%',
        borderBottom: '1px',
        borderColor: "#00000066",
    },
    nameText: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '2 2 2 2'
    },
    addressContainer: {
        width: '100%',
        borderBottom: '1px',
        borderColor: "#00000066"
    },
    addressText: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '1 2 1 2'
    },
    phoneContainer: {
        flexDirection: 'row',
        width: '100%',
        borderBottom: '1px',
        borderColor: "#00000066",
    },
    phoneText: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '2 2 2 2'
    },
    stateCodeCountry: {
        flexDirection: 'row'
    },
    stateCodeCountryText: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '2 2 2 2'
    },
    state: {
        flexDirection: 'row',
        borderRight: '1px',
        borderColor: "#00000066",
        paddingRight: 4,
    },
    code: {
        flexDirection: 'row',
        borderRight: '1px',
        borderColor: "#00000066",
        paddingRight: 4,
    },
    country: {
        flexDirection: 'row'
    },
    billToParty: {
        flexDirection: 'column',
        width: '50%',
    },
    shipToParty: {
        borderColor: "#00000066",
        borderLeft: '1px',
        width: '50%'
    },
    key: {
        fontSize: '8px',
        fontWeight: 'normal'
    },
    value: {
        fontSize: '8px',
        fontWeight: 'bold',
    },
});


const InvoiceBillDeliveryAddress = ({ billToPartyDetails, shipToPartyDetails }) => (
    <View style={styles.mainContainer}>
        <View style={styles.billToParty}>
            <View style={styles.titleContainer}>
                <Text style={styles.titleText}>BILL TO PARTY</Text>
            </View>
            <View style={styles.nameContainer}>
                <Text style={styles.nameText}>{billToPartyDetails.name}</Text>
            </View>
            <View style={styles.addressContainer}>
                <Text style={styles.addressText}>{billToPartyDetails.address.line1}</Text>
                <Text style={styles.addressText}>{billToPartyDetails.address.line2}</Text>
            </View>
            <View style={styles.phoneContainer}>
                <Text style={styles.phoneText}>Phone Number: </Text>
                <Text style={styles.phoneText}>{billToPartyDetails.phoneNumber}</Text>
            </View>
            <View style={styles.stateCodeCountry}>
                <View style={styles.state}>
                    <Text style={styles.stateCodeCountryText}>State:</Text>
                    <Text style={styles.stateCodeCountryText}>{billToPartyDetails.state}</Text>
                </View>
                <View style={styles.code}>
                    <Text style={styles.stateCodeCountryText}>Code:</Text>
                    <Text style={styles.stateCodeCountryText}>{billToPartyDetails.code}</Text>
                </View>
                <View style={styles.country}>
                    <Text style={styles.stateCodeCountryText}>Country:</Text>
                    <Text style={styles.stateCodeCountryText}>{billToPartyDetails.country}</Text>
                </View>
            </View>
        </View>
        <View style={styles.shipToParty}>
            <View style={styles.titleContainer}>
                <Text style={styles.titleText}>SHIP TO PARTY/DELIVERY ADDRESS</Text>
            </View>
            <View style={styles.nameContainer}>
                <Text style={styles.nameText}>{shipToPartyDetails.name}</Text>
            </View>
            <View style={styles.addressContainer}>
                <Text style={styles.addressText}>{shipToPartyDetails.address.line1}</Text>
                <Text style={styles.addressText}>{shipToPartyDetails.address.line2}</Text>
            </View>
            <View style={styles.phoneContainer}>
                <Text style={styles.phoneText}>Phone Number: </Text>
                <Text style={styles.phoneText}>{shipToPartyDetails.phoneNumber}</Text>
            </View>
            <View style={styles.stateCodeCountry}>
                <View style={styles.state}>
                    <Text style={styles.stateCodeCountryText}>State:</Text>
                    <Text style={styles.stateCodeCountryText}>{shipToPartyDetails.state}</Text>
                </View>
                <View style={styles.code}>
                    <Text style={styles.stateCodeCountryText}>Code:</Text>
                    <Text style={styles.stateCodeCountryText}>{shipToPartyDetails.code}</Text>
                </View>
                <View style={styles.country}>
                    <Text style={styles.stateCodeCountryText}>Country:</Text>
                    <Text style={styles.stateCodeCountryText}>{shipToPartyDetails.country}</Text>
                </View>
            </View>
        </View>
    </View>
);

export default InvoiceBillDeliveryAddress;