import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    mainContainer: {
        flexDirection: 'row',
        marginTop: 4,
        marginLeft: 5,
        justifyContent: 'space-between',
        width: '100%',
        color: "#00000075"
    },
    leftContainer: {
        // 
    },
    rightContainer: {
        alignSelf: 'flex-end',
        marginRight: 5
    }
});


const InvoicePageNumber = ({ pageNumber, numPages }) => (
    <View style={styles.mainContainer}>
        <View style={styles.leftContainer}>
            <Text style={{
                fontSize: '7px'
            }}>
                Thank you for your business
            </Text>
        </View>
        <View style={styles.rightContainer}>
            <Text style={{
                fontSize: '7px'
            }}>
                Page {pageNumber} of {numPages}
            </Text>
        </View>
    </View>
);

export default InvoicePageNumber