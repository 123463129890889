import React from 'react';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import InvoiceHeader from './InvoiceHeader';
import InvoiceOrgAddress from './InvoiceOrgAddress';
import InvoiceDetails from './InvoiceDetails';
import InvoiceBillDeliveryAddress from './InvoiceBillDeliveryAddress';
import {
    Table,
    TableBody,
    InvoiceTableHeader,
    InvoiceTableFooter,
    InvoiceTableRow
} from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';
import InvoicePaymentDetails from './InvoicePaymentDetails';
import InvoicePageNumber from './InvoicePageNumber';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignContent: 'center'
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Invoice = ({ invoice }) => {
    let totalNumPages = 0;
    let itemsMapArray = [];
    const itemsList = invoice.items;
    const itemLength = invoice.items.length;
    if (itemLength <= 3) {
        totalNumPages = 1
        itemsMapArray.push(invoice.items)
    } else {
        let itemLengthLeft = itemLength;
        let sliceStart = 0;
        if (itemLength >= 6) {
            while (itemLengthLeft > 0) {
                itemsMapArray.push(itemsList.slice(sliceStart, sliceStart + 6));
                sliceStart = sliceStart + 6;
                itemLengthLeft = itemLengthLeft - 6;
                totalNumPages++;
            }
            if (itemLength % 6 === 0) {
                itemsMapArray.push([]);
                totalNumPages++;
            }
        } else {
            totalNumPages = 2;
            itemsMapArray.push(itemsList.slice(0, itemLength % 6));
            itemsMapArray.push([]);
        }
    }
    // each page will contain [x] items only
    // eveything before items table will reappear on
    // every page of the invoice

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {
                    itemsMapArray.map((itemList, index) => (
                        <View style={{
                            width: '98%',
                            height: '98%',
                            marginTop: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            borderColor: '#000000',
                            border: '1px'
                        }} break={index > 0}>
                            <InvoiceHeader title="Tax Invoice" gstin={invoice.gstin} cin={invoice.cin} panNumber={invoice.panNumber} />
                            <InvoiceOrgAddress storeAddress={invoice.storeAddress} />
                            <InvoiceDetails invoiceDetails={invoice.invoiceDetails} />
                            <InvoiceBillDeliveryAddress billToPartyDetails={invoice.billToPartyDetails} shipToPartyDetails={invoice.shipToPartyDetails} />
                            <View style={{
                                flexDirection: 'column',
                                marginTop: 5,
                                borderColor: "#00000066",
                                justifyContent: 'space-between'
                            }}>
                                <Table>
                                    <InvoiceTableHeader />
                                    {
                                        itemList.length > 0 && (
                                            <TableBody>
                                                {
                                                    itemList.map(item =>
                                                        <InvoiceTableRow item={item} />
                                                    )
                                                }
                                            </TableBody>
                                        )
                                    }
                                    {
                                        (index + 1 === totalNumPages) &&
                                        (<InvoiceTableFooter totalQty={invoice.items_total.qty} amount={invoice.items_total.amount} />)
                                    }
                                </Table>
                            </View>
                            {
                                (index + 1 === totalNumPages) &&
                                (<InvoicePaymentDetails paymentDetails={invoice.paymentDetails} />)
                            }
                            {
                                (index + 1 === totalNumPages) &&
                                (<InvoiceThankYouMsg />)
                            }
                            <View style={{
                                position: 'absolute',
                                top: '100%',
                                width: '98%',
                            }}>
                                <InvoicePageNumber numPages={totalNumPages} pageNumber={index + 1} />
                            </View>
                        </View>
                    ))
                }
            </Page>
        </Document>
    )
};

export default Invoice