import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'column',
        marginTop: 4,
    },
    firstRow: {
        flexDirection: 'row',
        borderColor: '#000000',
        borderTop: '1px',
        justifyContent: 'flex-start'
    },
    secondRow: {
        flexDirection: 'row',
        borderColor: '#000000',
        borderTop: '1px',
    },
    thirdRow: {
        flexDirection: 'row',
        borderColor: '#000000',
        borderTop: '1px',
        borderBottom: '1px'
    },
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    key: {
        fontSize: '8px',
        fontWeight: 'normal'
    },
    value: {
        fontSize: '8px',
        fontWeight: 'bold'
    },
    invoiceNumber: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        margin: '5 200 5 5'
    },
    orderNumber: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderLeft: '1px'
    },
    invoiceDate: {
        flexDirection: 'row',
        margin: '5 75 5 5'
    },
    orderDate: {
        flexDirection: 'row',
        borderRight: '1px',
        borderLeft: '1px'
    },
    dateOfSupply: {
        flexDirection: 'row',
        margin: '5 10 5 5'
    },
    state: {
        flexDirection: 'row',
        margin: '5 75 5 5'
    },
    code: {
        flexDirection: 'row',
        borderRight: '1px',
        borderLeft: '1px'
    },
    placeOfSupply: {
        flexDirection: 'row',
        margin: '5 10 5 5'
    }
});


const InvoiceDetails = ({ invoiceDetails }) => (
    <View style={styles.mainContainer}>
        <View style={styles.firstRow}>
            <View style={styles.invoiceNumber}>
                <Text style={styles.key}>Invoice Number: </Text>
                <Text style={styles.value}>{invoiceDetails.invoiceNumber}</Text>
            </View>

            <View style={styles.orderNumber}>
                <View style={{ margin: '5 5 5 5', flexDirection: 'row' }}>
                    <Text style={styles.key}>Order Number: </Text>
                    <Text style={styles.value}>{invoiceDetails.orderNumber}</Text>
                </View>
            </View>
        </View>
        <View style={styles.secondRow}>
            <View style={styles.invoiceDate}>
                <Text style={styles.key}>Invoice Date : </Text>
                <Text style={styles.value}>{invoiceDetails.invoiceDate}</Text>
            </View>
            <View style={styles.orderDate}>
                <View style={{ margin: '5 120 5 5', flexDirection: 'row' }}>
                    <Text style={styles.key}>Order Date : </Text>
                    <Text style={styles.value}>{invoiceDetails.orderDate}</Text>
                </View>
            </View>
            <View style={styles.dateOfSupply}>
                <Text style={styles.key}>Date Of Supply : </Text>
                <Text style={styles.value}>{invoiceDetails.dateOfSupply}</Text>
            </View>
        </View>
        <View style={styles.thirdRow}>
            <View style={styles.state}>
                <Text style={styles.key}>State : </Text>
                <Text style={styles.value}>{invoiceDetails.state}</Text>
            </View>

            <View style={styles.code}>
                <View style={{ margin: '5 120 5 5', flexDirection: 'row' }}>
                    <Text style={styles.key}>Code : </Text>
                    <Text style={styles.value}>{invoiceDetails.code}</Text>
                </View>
            </View>

            <View style={styles.placeOfSupply}>
                <Text style={styles.key}>Place Of Supply : </Text>
                <Text style={styles.value}>{invoiceDetails.placeOfSupply}</Text>
            </View>
        </View>
    </View>
);

export default InvoiceDetails;