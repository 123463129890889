import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000';

const config = {
    primaryColor: '#00000010',
    primaryRGB: [0, 0, 0],
    accentColor: '#000000',
    blackColor: '#000000',
    grayColor: '#000000',
    lightGrayColor: '#000000',
    sectionTitleFontSize: 13,
    fieldTitleFontSize: 11,
    normalFontSize: 10,
    dateFormat: 'DD MM YYYY'
}

const styles = StyleSheet.create({
    table: {
        width: '100%'
    },
    mainContainer: {
        flexDirection: 'row',
        borderColor: borderColor,
        border: '1px'
    },
    firstContainer: {
        width: '50%',
    },
    secondContainer: {
        flexDirection: 'column',
        width: '50%',
        borderColor: borderColor,
        borderLeft: '1px',
        borderRight: '1px'
    },
    thirdContainer: {},
    text: {
        fontSize: '8px',
        fontWeight: 'bold',
        margin: '5 5 5 5',
        flexDirection: 'row'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderColor: borderColor,
        border: '1px',
        // backgroundColor: config.primaryColor,
        color: '#000'
    },
    headerCell: {
        fontWeight: 'heavy',
        white: '#000',
        width: '44.44%',
        fontSize: config.normalFontSize,
        margin: '5 15 5 5',
        flexDirection: 'row'
    },
    body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5'
    },
    cell: {
        width: '11.11%',
        margin: '5 5 5 5',
    },
    amountInWords: {
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 3px',
        borderColor: borderColor
    },
    paymentModesContainer: {
        flexDirection: 'row'
    },
    paymentModeName: {

    }
})
const Table = ({ children }) => <View style={styles.table}>{children}</View>

const TableHeaderNoBorder = ({ children }) => (
    <View style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderColor: borderColor,
        color: '#000',
        borderBottom: '1px'
    }}>{children}</View>
)
const TableHeaderCell = ({ children }) => (
    <Text style={styles.headerCell}>{children}</Text>
)

const InvoicePaymentDetails = ({ paymentDetails }) => (
    <View style={styles.mainContainer}>
        <View style={styles.firstContainer}>
            <Table>
                <TableHeaderNoBorder>
                    <TableHeaderCell>
                        <Text style={{
                            fontSize: '8px',
                            margin: '5 5 5 5',
                            flexDirection: 'row'
                        }}>Payment mode: </Text>
                        <Text style={{
                            fontSize: '10px',
                            fontWeight: '700',
                            margin: '5 5 5 5',
                            flexDirection: 'row'
                        }}>{paymentDetails.paymentModes}</Text>
                    </TableHeaderCell>
                </TableHeaderNoBorder>
                <TableHeaderNoBorder>
                    <TableHeaderCell>
                        <View style={{
                            flexDirection: 'column'
                        }}>
                            <View>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5'
                                }}>Total invoice amount in words: </Text>
                            </View>
                            <View>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5'
                                }}>{paymentDetails.amountInWords}</Text>
                            </View>
                        </View>
                    </TableHeaderCell>
                </TableHeaderNoBorder>
            </Table>
        </View>
        <View style={styles.secondContainer}>
            {/* {
                Object.keys(paymentDetails.modeWisePayment).map((key, index) => (
                    <View style={{
                        flexDirection: 'row',
                    }}>
                        <View style={{
                            borderColor: borderColor,
                            borderRight: '1px',
                            borderBottom: Object.keys(paymentDetails.modeWisePayment).length === index + 1 ? '0px' : '1px',
                            width: '20%'
                        }}>
                            <Text style={styles.text}>
                                {key}
                            </Text>
                        </View>
                        <View style={{
                            flexDirection: 'column',
                            width: '80%',
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                borderColor: borderColor,
                                borderBottom: Object.keys(paymentDetails.modeWisePayment).length === index + 1 ? '0px' : '1px',
                                width: '100%'
                            }}>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5',
                                    flexDirection: 'row',
                                    width: '80%'
                                }}>
                                    Amount
                                </Text>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5',
                                    flexDirection: 'row',
                                    textAlign: 'right',
                                    alignSelf: 'flex-end',
                                    width: '20%'
                                }}>
                                    {paymentDetails.modeWisePayment[key]['totalAmountBeforeTax']}
                                </Text>
                            </View>
                        </View>
                    </View>
                ))
            } */}
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderBottom: '1px',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Total :
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.totalAmount}
                    </Text>
                </View>
            </View>

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderRight: '1px',
                    borderBottom: '1px',
                    width: '50%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Discount Percent :
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.discountPercent}
                    </Text>
                </View>
                    <View style={{
                        borderColor: borderColor,
                        borderBottom: '1px',
                        width: '50%',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Text style={styles.text}>
                            Discount Amount:
                        </Text>
                        <Text style={styles.text}>
                            {paymentDetails.discountAmount}
                        </Text>
                    </View>
                </View>

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderBottom: '1px',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Total Taxable Amount :
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.totalTaxableAmount}
                    </Text>
                </View>
            </View>

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderTop: '0px',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Total Tax Amount :
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.totalTaxAmount}
                    </Text>
                </View>
            </View>

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderTop: '1px',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Round Off :
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.roundOff}
                    </Text>
                </View>
            </View>

            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
                <View style={{
                    borderColor: borderColor,
                    borderTop: '1px',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <Text style={styles.text}>
                        Total Payable:
                    </Text>
                    <Text style={styles.text}>
                        {paymentDetails.totalPayable}
                    </Text>
                </View>
            </View>
            {
                Object.keys(paymentDetails.modeWisePayment).map((key, index) => (
                    <View style={{
                        flexDirection: 'row',
                    }}>
                        <View style={{
                            borderColor: borderColor,
                            borderRight: '1px',
                            borderTop: '1px',
                            borderBottom: Object.keys(paymentDetails.modeWisePayment).length === index + 1 ? '0px' : '1px',
                            width: '20%'
                        }}>
                            <Text style={styles.text}>
                                {key}
                            </Text>
                        </View>
                        <View style={{
                            flexDirection: 'column',
                            width: '80%',
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                borderColor: borderColor,
                                borderTop: '1px',
                                borderBottom: Object.keys(paymentDetails.modeWisePayment).length === index + 1 ? '0px' : '1px',
                                width: '100%'
                            }}>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5',
                                    flexDirection: 'row',
                                    width: '80%'
                                }}>
                                    Amount
                                </Text>
                                <Text style={{
                                    fontSize: '8px',
                                    fontWeight: 'bold',
                                    margin: '5 5 5 5',
                                    flexDirection: 'row',
                                    textAlign: 'right',
                                    alignSelf: 'flex-end',
                                    width: '20%'
                                }}>
                                    {paymentDetails.modeWisePayment[key]['totalAmountBeforeTax']}
                                </Text>
                            </View>
                        </View>
                    </View>
                ))
            }
        </View>
    </View>
);

export default InvoicePaymentDetails;