import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { isMobile } from 'react-device-detect';


// const productPath = ""

// const iosUrl = "https://apps.apple.com/in/app/fablestreet-women-western-wear/id6443497859";
// const androidUrl = "https://play.google.com/store/apps/details?id=com.fablestreet&hl=en_IN&gl=US";
// const playstoreIntent = "market://details?id=com.fablestreet"

// const homePageUri = `fablestreet://fablestreet.com`;

export default function Home() {
    // const getMobileOperatingSystem = () => {
    //     var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //     // Windows Phone must come first because its UA also contains "Android"
    //     if (/windows phone/i.test(userAgent)) {
    //         return "Windows Phone";
    //     }
    //     if (/android/i.test(userAgent)) {
    //         return "Android";
    //     }
    //     // iOS detection from: http://stackoverflow.com/a/9039885/177710
    //     if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //         return "iOS";
    //     }
    //     return "unknown";
    // }

    // useEffect(() => {
    //     if (isMobile) {
    //         const osType = getMobileOperatingSystem();
    //         if (osType === "Android") {
    //             // document.getElementById("l").src = homePageUri;
    //             window.location = homePageUri;
    //             setTimeout(function () {
    //                 window.location = playstoreIntent;
    //             }, 500);
    //         } else {
    //             window.location = `https://www.fablestreet.com/${productPath}`;
    //         }
    //     } else {
    //         window.location = `https://www.fablestreet.com/${productPath}`;
    //     }
    // }, []);

    useEffect(() => {
        window.location = `https://www.fablestreet.com/`;
    }, [])

    return (
        <p>Redirecting ....</p>
    )
}