import React from 'react';
import { Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';

const FS_LOGO_URL = 'https://res.cloudinary.com/dfvcuxviq/image/upload/v1712210714/invoice/Logo_FS_new_jun23_280x_scw0ka.png'

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'column',
        marginTop: 4,
    },
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    logo: {
        width: '100px',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    fsTitle: {
        color: '#000000',
        marginTop: 3,
        fontSize: '8px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    fsCompleteName: {
        color: '#000000',
        fontSize: '8px',
        textAlign: 'center'
    },
    storeAddress: {
        color: '#000000',
        fontSize: '8px',
        textAlign: 'center',
    },
    webAddress: {
        color: '#000000',
        fontSize: '8px',
        textAlign: 'center',
    },
});


const InvoiceOrgAddress = ({ storeAddress }) => (
    <View style={styles.titleContainer}>
        <Image style={styles.logo} src={FS_LOGO_URL} />
        <Text style={styles.fsTitle}>Fable Street</Text>
        <Text style={styles.fsCompleteName}>Fable Street Lifestyle Solutions Private Limited</Text>
        <Text style={styles.storeAddress}>{storeAddress}</Text>
        <Text style={styles.webAddress}><Link src='https://www.fablestreet.com/'>www.fablestreet.com</Link></Text>
    </View>
);

export default InvoiceOrgAddress;