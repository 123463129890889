import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { isMobile } from "react-device-detect";
import Invoice from '../components/reports/Invoice';
import { Button } from "@mui/material";

export default function FsStore() {
    const parameters = useParams();
    const orderId = parameters?.orderId;
    const uuid = parameters?.uuid;
    const [invoice, setInvoice] = useState({});
    const [loading, setLoading] = useState(true);
    const [preparing, setPreparing] = useState(false);

    useEffect(() => {
        async function fetchInvoice() {
            const invoiceData = await getInvoice();
            if (invoiceData) {
                setInvoice(invoiceData);
            }
        }
        fetchInvoice();
    }, []);

    useEffect(() => {
        const invoiceKeys = Object.keys(invoice);
        if (invoiceKeys.length) {
            setLoading(false);
        }
    }, [invoice]);

    const getInvoice = async () => {
        const invoiceData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v0/api/invoice/${orderId}/${uuid}`, {
            method: "GET",
            headers: {
                "X-API-Key": "y$%43dd?3432@$%$2",
            },
        })
            .then(async (response) => response.json())
            .then(async (data) => {
                if (data.success) {
                    return data.data;
                } else {
                    return null;
                }
            })
            .catch(async (error) => {
                console.log(error)
                return null;
            });
        return invoiceData;
    }

    // return (
    //     <>
    //         {
    //             !loading
    //                 ? (
    //                     <PDFViewer width={'100%'} height={window.innerHeight} className="app" >
    //                         <Invoice invoice={invoice} />
    //                     </PDFViewer>
    //                 )
    //                 : (<><h1>Loading ...</h1></>)
    //         }
    //     </>
    // )
    return (
        <>
            {
                isMobile
                    ? (
                        <>
                            {
                                !loading
                                    ? (
                                        <PDFDownloadLink
                                            document={<Invoice invoice={invoice} />}
                                            fileName="fablestreet_invoice.pdf"
                                            style={{
                                                color: "black"
                                            }}
                                        >
                                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : (
                                                <>
                                                    <Button
                                                        className="app"
                                                        variant="outlined"
                                                        sx={{
                                                            margin: "5px",
                                                            color: "black",
                                                            borderColor: "black",
                                                            width: "50vw",
                                                            top: "40vh",
                                                            left: "20vw"
                                                        }}
                                                    >
                                                        <strong>Download Invoice</strong>
                                                    </Button>

                                                </>
                                            ))}
                                        </PDFDownloadLink>
                                    )
                                    : (<><h1>Loading ...</h1></>)
                            }
                        </>
                    )
                    : (
                        <>
                            {
                                !loading
                                    ? (
                                        <PDFViewer width={'100%'} height={window.innerHeight} className="app" >
                                            <Invoice invoice={invoice} />
                                        </PDFViewer>
                                    )
                                    : (<><h1>Loading ...</h1></>)
                            }
                        </>
                    )
            }
        </>

    );

    // return (
    //     <>
    //         <Fragment>
    //             <PDFViewer width={'100%'} height={window.innerHeight} className="app" >
    //                 <Invoice invoice={invoice1} />
    //             </PDFViewer>
    //         </Fragment>
    //     </>
    // )
}