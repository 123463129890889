import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './index.css';
import FsStore from './pages/FsStore';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

const router = createBrowserRouter([
  {
    path: "/invoice/:orderId/:uuid",
    element: <FsStore />
  },
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/store/:locationId/login",
    element: <Login />
  },
  {
    path: "/store/:locationId/dashboard",
    element: <Dashboard />
  }
  // removing the path below, not required
  // {
  //   path: "/products/:handle",
  //   element: <Product />
  // }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
